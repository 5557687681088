export default {
  data() {
    return {};
  },
  methods: {
    getElement(element) {
      let anchorH = document.getElementById(element).offsetTop - 100;

      if (document.documentElement.scrollTop) {
        document.documentElement.scrollTop = anchorH;
      } else if (document.body.scrollTop) {
        document.body.scrollTop = anchorH;
      } else {
        //二者均为0状态，有一者恒为0，另一者可能因为回到顶部等操作被置为0，便会出现这种状况
        document.documentElement.scrollTop = anchorH;
        document.body.scrollTop = anchorH;
      }
    },
  },
};
